import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Title = () => {
    const location = useLocation();
    const pathname = location.pathname;

    useEffect(() => {
        let title = 'SIST ACM SIGAI Student chapter Student chapter';

        switch (pathname) {
            case '/about':
                title = 'ABOUT US - SIST ACM SIGAI Student chapter';
                break;
                case '/events':
                title = 'ARCHIVES - SIST ACM SIGAI Student chapter';
                break;
                case '/membership':
                title = 'MEMBERSHIP - SIST ACM SIGAI Student chapter';
                break;
                case '/blogs':
                title = 'BLOGS - SIST ACM SIGAI Student chapter';
                break;
                case '/recruitment':
                title = 'RECRUITMENT - SIST ACM SIGAI Student chapter';
                break;
                case '/fest':
                title = 'FEST - SIST ACM SIGAI Student chapter';
                break;
            
            default:
                title = 'SIST ACM SIGAI Student chapter';
        }

        document.title = title;
    }, [pathname]);

    return null;
};

export default Title;

