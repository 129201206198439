import React from 'react';
import {motion as m} from "framer-motion";
import {fadeIn} from '../components/transitions';

import spider from '../images/spiderman.jpg';
import winner from '../images/WINNER.jpg';
import winners from '../images/winners.jpg';
const Azures = () => {
  return (
    <>
      <div className='digitalart'>
        <div className='aboutsec'>
          <m.h2 variants={fadeIn("up",0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}}  
                className='about-heading'>DIGITAL <span>ART</span>
          </m.h2>
          <m.p variants={fadeIn("up",0.2)}
               initial="hidden"
               whileInView={"show"}
               viewport={{once: false, amount: 0.7}} 
               className='about-paragraph'>
            We are thrilled to announce that the SIST ACM SIGAI Student Chapter hosted digital art event on August 1, 2024, in the Remibai Auditorium at Sathyabama Institute of Science and Technology, Chennai. 
          </m.p>
          <m.h2 variants={fadeIn("up",0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}}  
                className='about-heading'>WINNER's <span>ARTWORK</span>
          </m.h2>
          <m.div variants={fadeIn("up",0.2)}
                 initial="hidden"
                 whileInView={"show"}
                 viewport={{once: false, amount: 0.7}} 
                 className='digi-container'>
            <img src={spider} alt='New Artwork' className='digi-image' />
          </m.div>
          <m.h2 variants={fadeIn("up",0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}}  
                className='about-heading'>EVENT <span>WINNER</span>
          </m.h2>
          <m.div variants={fadeIn("up",0.2)}
                 initial="hidden"
                 whileInView={"show"}
                 viewport={{once: false, amount: 0.7}} 
                 className='card-container1'>
            <div className='cards1'>
              <div className='imbx151'>
                <img src={winner} alt='img' />
              </div>
              <div className='contents'>
                <div className='contentbx'>
                  <h3>Mr. Godwin Deepak T<br /><span>3rd year CSE, WINNER</span></h3>
                </div>
              </div>
            </div>
          </m.div>

         
       

          <m.h2 variants={fadeIn("up",0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 
                className='about-heading'>🔍KEY <span>HIGHLIGHTS</span>
          </m.h2>
          <m.p variants={fadeIn("up",0.2)}
               initial="hidden"
               whileInView={"show"}
               viewport={{once: false, amount: 0.7}} 
               className='about-paragraph'>
           The SIST ACM SIGAI Student Chapter recently conducted an artistic "Digital Art Competition" for the students of the CSE department on August 1, 2024. It was a remarkable event where participants enthusiastically showcased their talent in digital technology through their excellent artistic skills. The theme of this exciting competition was freestyle comics, and the artist who captured the hearts of the audience with his work was <strong>Godwin Deepak T</strong>.
          </m.p>
          <m.div variants={fadeIn("up",0.2)}
                 initial="hidden"
                 whileInView={"show"}
                 viewport={{once: false, amount: 0.7}} 
                 className='digi-containers'>
            <img src={winners} alt='New Artwork' className='digi-images' />
            </m.div>
        </div>
      </div>
    </>
  );
}

export default Azures;
