import React from 'react'
import sc from '../images/final_iguess-removebg.png'
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <>
        {/* <footer class="footer">
        <div class="start-learning">
            <div class="footer-start">
                <div class="texts">
                    
                    <h2 class="section-title">ACM-SIST<br/>SIGAI</h2>
                    <h3 class="section-sub-title"><strong>#1 FIRST SIGAI IN TAMILNADU</strong></h3>
                </div>
                <Link to="/Login" className="button">
                <span className="label">FEST</span>
                 </Link>
                <img class="illustration" src={sc} width="120" height="94"></img>
            </div>
        </div>
		<div class="inner">
            <div class="column is-logo">
                <a href="#" class="main-logo">
                    
                    <div class="logo-info">
                        <div class="text">ACM-SIST SIGAI</div>
                        <span class="copyright">© 2024. All rights reserved.</span>
                    </div>
                </a>
            </div>
            <div class="column is-navigation">
                <div class="column-title">Navigation</div>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/membership">Membership</Link></li>
                    <li><Link to="/events">Events</Link></li>
                </ul>
            </div>
            <div class="column is-navigation">
                
                <div className='social'>
             <a href={'#'}><i class="fab fa-facebook-f"></i></a><break></break>
             <a href={'#'}><i class="fab fa-twitter"></i></a>
             <a href={'https://www.instagram.com/sist_sigai?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/sist-acm-sigai-student-chapter/'}><i class="fab fa-linkedin"></i></a>
             </div>
                
            </div>
            <div class="column is-navigation">
                <div class="column-title">Blog</div>
                <ul>
                    <li><Link to="/blogs">Blog1</Link></li>
                    <li><Link to="/blogs">Blog2</Link></li>
                    <li><Link to="/blogs">Blog3</Link></li>
                </ul>
            </div>
		</div>
	</footer> */}
        </>
)
}


export default Footer