import React from 'react'
import {motion as m} from "framer-motion";
import {fadeIn} from '../components/transitions'
import chief3 from '../images/chief3.JPG'

import log from '../images/logo.JPG'

import id2 from '../images/meghana id.JPG'
import grp1 from '../images/grp.JPG'
import grp2 from '../images/grp2.JPG'
import vai from '../images/vai.JPG'
import sq from '../images/sq.JPG'
import mani from '../images/man.JPG'
import adi from '../images/adi1.JPG'
import sat from '../images/Sathyabama Institute of Science and Technology.png'
const Eventss = () => {
  return (
    <>
    <div className='inau'>
   <div className='aboutsec'>
                <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='about-heading'>INAUGURATION OF <span>SIST ACM SIGAI</span></m.h2>
                <m.p
                variants={fadeIn("up",0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}}  className='about-paragraph'>
                🎉We are excited to share that the SIST ACM SIGAI Student Chapter has been successfully inaugurated on 8th April 2024 (Monday) at Tmt. 
                Soundrabai Auditorium, Sathyabama Institute of Science & Technology, Chennai. We would like to thank all the Admins, Deans, HoDs, Faculties and Students who supported us.🎉
                🌟Graced by the presence of esteemed dignitaries, Vice President -<strong>Ms. Maria Catherine Jayapriya</strong>, Vice Chancellor -<strong> Dr. Sasipraba T</strong>, Chief Guest - <strong>Ms. Rajalakshmi Srinivasan</strong>,
                 Director Administration -<strong>Dr. Sundari G</strong>, Dean COMPUTING -<strong> Dr. T. Sasikala</strong>, Dean of Dental Sciences -<strong>DR.SHAHUL HAMEED FAIZEE</strong>, Dean of Placement - <strong>Dr. John Bruce</strong>, Dean of Arts and Sciences -<strong>Dr.Rekha Chakravarthi</strong>, Dean of IQAC - <strong>Dr. Anima Nanda</strong>, Head of the Department -<strong>Dr. S. Vigneshwari</strong> ,
                  Head of the Department - <strong>Dr. Lakshmanan L</strong>.🌟
                </m.p>
                <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  className='about-heading'>EVENT <span>GALLERY</span></m.h2>
                
                <div className='sl'>
    <div className='slider'>
      <div className='slide-track'>
        <div className='slide'>
        <img src={vai} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={sat} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={mani} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={adi} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={log} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={sq} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={id2} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={grp1} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={grp2} alt='logo'/>
        </div>

        <div className='slide'>
        <img src={vai} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={sat} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={mani} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={adi} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={log} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={sq} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={id2} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={grp1} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={grp2} alt='logo'/>
        </div>

      </div>
    </div>
    
</div>
<div className='about-heading'>GUESTS OF <span>HONOUR</span></div>
<m.div variants={fadeIn("right",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  className='card-container1'>
   
      <div className='cards1'>
        <div className='imbx15'>
          <img src={chief3} alt='img' />
        </div>
        <div className='contents'>
          <div className='contentbx'>
            <h3>Ms. Rajalakshmi Srinivasan<br /><span>Director - Product Management, Zoho</span></h3>
          </div>
          
        </div>
      </div>
      </m.div>
      <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  className='about-heading'>🔍KEY <span>HIGHLIGHTS</span></m.h2>
      <m.p variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  className='about-paragraph'>Ceremonial lighting of the lamp followed by a welcome address by<strong> Dean of School of Computing, Dr. T. Sasikala.
Mrs. Rajalakshmi Srinivasan, Director of Product Management at Zoho Corporations</strong>, emphasized the importance of practical learning.
<strong>Vice President Ms. Maria Catherine Jayapriya</strong> pledged support for the club's initiatives.
Vice Chancellor Dr. T. Sasiprabha stressed inter-departmental collaboration.<br/>
<strong>Our Chairperson, Mr. Gowtham S</strong>, expressed gratitude to supporters and introduced the core unit.
 
<strong>The Head of Computer Science and Engineering department, Dr. S. Vigneshwari</strong> took a moment to express her appreciation for the Chairperson, Vice Chairperson, and every member of the core unit and she spoke very enthusiastically about the projects related to AI and her vision to bring the community together.<br/>
 
<strong>Our Dean of Computing, Dr. T. Sasikala</strong>, specifically highlighted Chairperson, Vice Chairperson and Core Unit in their proactive efforts in extending invitations to all School Deans of Sathyabama.

The core unit of the club were introduced by the Chairperson. Each and every member was called onto the stage and was presented with ID cards by our respectful Vice president mam and Vice Chancellor mam. They congratulated everyone personally and told that they’re looking forward to the work we’re going to do.

Recognizing the foundation of our SIST ACM SIGAI Student Chapter – our esteemed pillars: Faculty Sponsor - <strong>Dr. S. Vigneshwari, along with Faculty Coordinators - Dr. Sathya Bama Krishna R and Dr. B.U. Anu Barathi</strong>, for their invaluable support.
                
                </m.p><br/>
                <m.p variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  className='about-paragraph'>
                Our Chairperson - Gowtham S, Vice Chairperson – Bharath Kodidasu, Treasurer – Deekshitha Uppu, Our Core Unit - BELLAMKONDA HARITHREENATH, Aditya Sai Teja B, Siva Krishna Adimulam, Manisri Venkatesh, Meghana Tanikella, BATTINA VAISHNAVI, Niharika Ramayanam, D V BHUVANESH, Ram Prasath, sushree sonali patra, Vedha Varshini Vijay Ananth, Faheem mohamed rafi, Devendra Reddy, Janllyn Avantikha.
 
 Overall it was a great support and encouragement from everyone. Looking forward to AI-driven future.
                </m.p>

            </div>








   
    </div>
    </>
  )
}
export default Eventss