import React from 'react';
import {motion as m} from "framer-motion";
import { Link } from 'react-router-dom';
import {fadeIn} from '../components/transitions'
import log from '../images/videog.jpg'
import styles from './event.module.css';
const Recruitment = () => {
  return (
    <>
     <div className='Time'>
        <section className='Time-wrapper'>
          <figure className='recruitment-figure'>
            <div className="dot white"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </figure>
          <div id='stars'></div>
          <div id='stars2'></div>
          <div id='stars3'></div>
          <div id='Time-title'>
            <span> Stay tuned for <br /> upcoming Recruitments</span>
          </div>
        </section>
      </div>
     
    </>
  )
}

export default Recruitment;
