import React from 'react';


const Apex = () => {
    return (
        <div className="banner">
            <div className="sliderz" style={{ '--quantity': 10 }}>
                <div className="item" style={{ '--position': 1 }}>
                    <img src="images/dragon_1.jpg" alt="Dragon 1" />
                </div>
                <div className="item" style={{ '--position': 2 }}>
                    <img src="images/dragon_2.jpg" alt="Dragon 2" />
                </div>
                <div className="item" style={{ '--position': 3 }}>
                    <img src="images/dragon_3.jpg" alt="Dragon 3" />
                </div>
                <div className="item" style={{ '--position': 4 }}>
                    <img src="images/dragon_4.jpg" alt="Dragon 4" />
                </div>
                <div className="item" style={{ '--position': 5 }}>
                    <img src="images/dragon_5.jpg" alt="Dragon 5" />
                </div>
                <div className="item" style={{ '--position': 6 }}>
                    <img src="images/dragon_6.jpg" alt="Dragon 6" />
                </div>
                <div className="item" style={{ '--position': 7 }}>
                    <img src="images/dragon_7.jpg" alt="Dragon 7" />
                </div>
                <div className="item" style={{ '--position': 8 }}>
                    <img src="images/dragon_8.jpg" alt="Dragon 8" />
                </div>
                <div className="item" style={{ '--position': 9 }}>
                    <img src="images/dragon_9.jpg" alt="Dragon 9" />
                </div>
                <div className="item" style={{ '--position': 10 }}>
                    <img src="images/dragon_10.jpg" alt="Dragon 10" />
                </div>
            </div>
            <div className="content">
                <h1 data-content="SAGA 2024">SAGA 2024</h1>
                <div className="author">
                    <h2>SIST ACM SIGAI</h2>
                    <p><b>student chapter</b></p>
                    <p>Subscribe to the channel to watch many interesting videos</p>
                </div>
                <div className="model"></div>
            </div>
        </div>
    );
};

export default Apex;
