import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import sc from '../images/final_iguess-removebg.png';


const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="menu-logo-container" onClick={toggleMenu}>
        <i className="fas fa-bars" id="menu"></i>
        <div className="logo">
          <img src={sc} alt="logo" />
        </div>
      </div>
      <ul className={menuOpen ? 'menu open' : 'menu'}>
        <li><Link to="/" onClick={closeMenu}>Home</Link></li>
        <li><Link to="/about" onClick={closeMenu}>About Us</Link></li>
        <li><Link to="/membership" onClick={closeMenu}>Membership</Link></li>
        <li><Link to="/events" onClick={closeMenu}>Archives</Link></li>
        <li><Link to="/blogs" onClick={closeMenu}>Blogs</Link></li>
        <li><Link to="/recruitment" onClick={closeMenu}>WANNA JOIN US?</Link></li>
        {/* <li><Link to="/timeline" onClick={closeMenu}>Timeline</Link></li> */}
      </ul>
      <Link to="/fest" className="button-fest" onClick={closeMenu}>
        <span className="label">EVENTS</span>
      </Link>
    </nav>
  );
}

export default Nav;
