import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from '../images/logo_rounded.png';
import {motion as m} from "framer-motion";
import {fadeIn} from '../components/transitions'

const Blogs = () => {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const getPostData = () => {
    axios
      .get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@sist.sigaI')
      .then((res) => {
        const updatedPosts = res.data.items.map((item) => ({
          title: item.title,
          content: item.content ? item.content.substring(0, 200) + '...' : '',
          link: item.link,
        }));
        setPosts(updatedPosts);
      })
      .catch((error) => {
        console.error('Error fetching blog posts:', error);
      });
  };

  useEffect(() => {
    getPostData();
  }, []);

  const highlightText = (text, highlight) => {
    if (!highlight.trim()) {
      return text;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    return text.replace(regex, '<span class="highlight">$1</span>');
  };

  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
  //   <m.div 
  // initial={{ x: "100%"}} 
  // animate={{ x: "0%"}}
  // exit={{ opacity: 1}}
  // transition={{duration: 0.75, ease: "easeOut"}}
  // >
    <div className='Blog'>
      <div className='heading-line'></div>
      <div className='Blog-heading-container'>
        <div className="logo-and-heading">
          <m.div
           variants={fadeIn("left",0.2)}
           initial="hidden"
           whileInView={"show"}
           viewport={{once: false, amount: 0.7}} className="small-logo">
            <a href='https://medium.com/@sist.sigai' target='_blank' rel='noopener noreferrer'>
              <img src={logo} alt='SIST ACM SIGAI Logo' />
            </a>
          </m.div>
          <m.h2
          variants={fadeIn("left",0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.7}} className='Blog-heading'>
            SIST SIGAI <span>BLOGS</span>
          </m.h2>
        </div>
        <input
          type='text'
          className='search-bar'
          placeholder='Search blogs...'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className='heading-line'></div>
      <m.h1
      variants={fadeIn("right",0.2)}
      initial="hidden"
      whileInView={"show"}
      viewport={{once: false, amount: 0.7}} className='Main-heading'>SIST ACM SIGAI STUDENT CHAPTER</m.h1>
      <m.div
       variants={fadeIn("left",0.2)}
       initial="hidden"
       whileInView={"show"}
       viewport={{once: false, amount: 0.7}} className='Follow-button'>
        <a href='https://medium.com/@sist.sigai' target='_blank' rel='noopener noreferrer'>
          <button className='follow-button'>Follow us on Medium</button>
        </a>
      </m.div>
      <div className='heading-line1'></div>
      <div className='Blog-container'>
        {filteredPosts.map((post) => (
          <m.div
          variants={fadeIn("right",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}
           key={post.link} className='Blog-post'>
            <div className='Post-content'>
              <h3 dangerouslySetInnerHTML={{ __html: highlightText(post.title, searchTerm) }}></h3>
              <div className='post-content-html' dangerouslySetInnerHTML={{ __html: post.content }} />
              <a href={post.link} target='_blank' rel='noopener noreferrer'>
                <button className='read-more-button'>Read more</button>
              </a>
            </div>
          </m.div>
        ))}
      </div>
      <div className='heading-line1'></div>
      <button className='back-to-top' onClick={scrollToTop}>
        <i className="fa-solid fa-arrow-up"></i>
      </button>
    </div>
    // </m.div>
  );
};

export default Blogs;
