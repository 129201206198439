import React from 'react'
import {motion as m} from "framer-motion";
import {fadeIn} from '../components/transitions'

const Eventss = () => {
  return (
    <>
    <div className='synergy'>
   <div className='aboutsec'>
                <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='about-heading'>SYNERGY <span>FOR FRESHERS</span></m.h2>
                <m.p
                variants={fadeIn("up",0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}}  className='about-paragraph'>
                    We are happy to share that the SIST ACM SIGAI Student Chapter successfully conducted an exciting event for the new generation, "Synergy for Freshers," on 4th September 2024 (Wednesday) at the Dental Auditorium, Sathyabama Institute of Science and Technology, Chennai.


                </m.p>
                <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  className='about-heading'>EVENT <span>GALLERY</span></m.h2>
                
                <div className='sl2'>
    <div className='slider2'>
      <div className='slide-track'>
        <div className='slide'>
        <img src="https://i.postimg.cc/pLTybQMc/fre.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/W3450fB6/FRE1.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/PxF2yQhG/FRE2.avif" alt='logo'/>
        </div>
        
        <div className='slide'>
        <img src="https://i.postimg.cc/x8wgDjct/FRE3.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/NF9bz2fK/FRE4.jpg" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/CLDpZzHM/FRE5.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/wjp8Dg0X/FRE6.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/Njkh4SzW/FRE7.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/52cKDgzb/FRE8.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/KYf9kyH8/FRE9.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/GmkgRDPY/FRE10.jpg" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/xdnx1dgZ/FRE11.avif" alt='logo'/>
        </div>
        
        


        <div className='slide'>
        <img src="https://i.postimg.cc/pLTybQMc/fre.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/W3450fB6/FRE1.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/PxF2yQhG/FRE2.avif" alt='logo'/>
        </div>
        
        <div className='slide'>
        <img src="https://i.postimg.cc/x8wgDjct/FRE3.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/NF9bz2fK/FRE4.jpg" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/CLDpZzHM/FRE5.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/wjp8Dg0X/FRE6.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/Njkh4SzW/FRE7.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/52cKDgzb/FRE8.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/KYf9kyH8/FRE9.avif" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/GmkgRDPY/FRE10.jpg" alt='logo'/>
        </div>
        <div className='slide'>
        <img src="https://i.postimg.cc/xdnx1dgZ/FRE11.avif" alt='logo'/>
        </div>

      </div>
    </div>
    
</div>
{/* <div className='about-heading'>GUESTS OF <span>HONOUR</span></div>
<m.div variants={fadeIn("right",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  className='card-container1'>
   
      <div className='cards1'>
        <div className='imbx15'>
          <img src={s4} alt='img' />
        </div>
        <div className='contents'>
          <div className='contentbx'>
            <h3>Padma Shri Dr. Mylswamy Annadurai<br /><span>MOON MAN OF INDIA</span></h3>
          </div>
          
        </div>
      </div>
      </m.div> */}
      <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  className='about-heading'>🔍KEY <span>HIGHLIGHTS</span></m.h2>
      <m.p variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  className='about-paragraph'>The event began with our core unit members, <strong>Ms. Janllyn Avantikha and Ms. Vaishnavi Battina</strong>, introducing our Student Chapter to the audience, followed by a speech from our honorable Head of the Department,<strong>Dr. Vigneshwari. </strong> 

                    The first round of the event was a non-technical fun activity, "Trash to Treasure." All the students were divided into teams and provided with a bunch of trash and chart paper. They were tasked with creating something innovative from the materials they were given. The students showed immense enthusiasm and worked wonders with their creations. The results were evaluated by our faculty coordinators, <strong>Dr. R. Sathyabama Krishna and Dr. Anu Barathi</strong>. Two winners were selected from the teams.
                    
                    Following this was an orientation session where the members of our Student Chapter took the stage to explain the various future opportunities available to students after graduation. <strong>Ms. Sri Soundharya</strong>, Secretary of the Student Chapter, gave a detailed explanation of the current placement opportunities and how to aim for them. <strong>Ms. Sushree Sonali Patra</strong>, a core unit member, discussed entrepreneurship opportunities, highlighting its advantages and drawbacks with real-life examples. <strong>Ms. Vaishnavi Battina</strong>, another core unit member, familiarized the students with post-graduation degrees available to them, their eligibility criteria, and how to approach them. The students found this session extremely informative.
                    
                    <strong>Ms. Deekshitha</strong>, Treasurer of the Student Chapter, engaged with the students and spoke about the anxiety surrounding career decisions, offering advice on how to handle it and passionately pursue one's dreams.
                    
                    Overall, the event was a great success, and all the students thoroughly enjoyed it. We are thankful to all the dignitaries, faculty coordinators, student coordinators, and freshers who attended the event, contributing to its success. We look forward to organizing more such events in the future.
                </m.p><br/>
              

            </div>








   
    </div>
    </>
  )
}
export default Eventss