import React from "react";
import {motion as m} from "framer-motion";
import {fadeIn} from '../components/transitions'

const Membership = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
  //   <m.div 
  // initial={{ y: "100%"}} 
  // animate={{ y: "0%"}}
  // exit={{ opacity: 1}}
  // transition={{duration: 0.75, ease: "easeOut"}}
  // >
    <div className="member">
      <div className="memb">
        <h2 className='memb-heading'>ACM<span> MEMBERSHIP</span></h2>
        <div className="guidelines">
          <h1><strong>GUIDANCE FOR GETTING ACM MEMBERSHIP AND MEMBERSHIP CARD</strong></h1><br/>
          <h2><strong> USE ONLY LAPTOP OR PC FOR THE WHOLE PROCESS </strong></h2><br/><br/>
          <h3><strong>MOST IMPORTANT THING TO KNOW BEFORE APPLYING:</strong></h3>
          <ul><br/><br/>
            <li>1. DO NOT CREATE ANY ACCOUNT WITH ACM BEFORE PAYING AND GETTING STUDENT/PROFESSIONAL MEMBERSHIP AND PLEASE BE CAREFUL WITH THE EMAIL ID USED TO FILL THE FORM. KINDLY DOUBLE CHECK ON THE EMAIL ID. (Check for the proper spelling and verify it's your email id where you can receive your emails)</li>
          </ul>
          <br/>
          <p><strong><span>STEP 1:</span> To get student/Professional membership:</strong></p><br/>
          <div className="button-container">
            <a href="https://services.acm.org/public/qj/proflevel/proflevel_control.cfm?level=3&country=India&form_type=Student&promo=ACMMSDEPT&pay=DD" className="button" target="_blank" rel="noopener noreferrer">Get Student Membership</a>
          </div><br/>
          <div className="button-container">
            <a href="https://services.acm.org/public/qj/proflevel/proflevel_control.cfm?level=3&country=India&form_type=Professional&promo=LEVEL&pay=DD" className="button" target="_blank" rel="noopener noreferrer">Get Professional Membership</a>
          </div><br/>
          <p><strong><span>STEP 2:</span> After getting the student membership create an account with ACM using this link</strong></p>
          <br/>
          <p>USE THE SAME EMAIL ADDRESS WHICH WAS USED TO FILL THE FORM IN STEP 1. IN CASE OF USING WRONG EMAIL OR MISSPELLED THE EMAIL, YOU WILL FACE ERROR IN GETTING MEMBERSHIP CARD AND MY ACM ACCOUNT. SO PLEASE BE CAREFUL TO USE THE SAME EMAIL WITHOUT ERROR, WHICH WAS USED TO FILL THE FORM IN STEP 1</p>
          <div className="button-container">
            <br/>
            <a href="https://accounts.acm.org/signin.cfm" className="button" target="_blank" rel="noopener noreferrer">Create ACM Account</a>
          </div>
          <br/>
          <p><strong><span>STEP 3:</span> After creating an account you will receive an email with a verification link. Please click on that link and open the same in a browser. DON'T MODIFY ANYTHING OVER THAT LINK</strong></p>
          <br/>
          <p><strong><span>STEP 4:</span> After successful creation and verification of the account. Use this link to login into My ACM account.</strong></p>
          <p>In this step use the username which was autogenerated by ACM</p>
          <br/>
          <div className="button-container">
            <a href="https://myacm.acm.org/" className="button" target="_blank" rel="noopener noreferrer">Login to My ACM</a>
          </div>
          <br/>
          <p><strong><span>STEP 5:</span> When you successfully login using your username and password. If you scroll down you may find your Membership card in a PDF form. Download that and keep it safely.</strong></p>
          <br/>
          <p><strong><span>STEP 6:</span> In case if you can't find the Membership card, Use this link to access your dashboard. You may find the same here.</strong></p>
          <br/>
          <div className="button-container">
            <a href="https://myacm.acm.org/dashboard.cfm?svc=services" className="button" target="_blank" rel="noopener noreferrer">Access Dashboard</a>
          </div>
          <br/>
          <p>If you face any Technical issue kindly contact the ACM help desk and put a formal email to them. Explaining your situation in a detailed manner and add necessary screenshots if required.</p>
          <br/>
          <br/>
          <p>Email: <a href="mailto:acmindiahelp@hq.acm.org">acmindiahelp@hq.acm.org</a></p>
          <p>Regards,</p>
          <p>SIST ACM SIGAI student chapter</p>
          <button className='back-to-top' onClick={scrollToTop}>
        <i className="fa-solid fa-arrow-up"></i>
      </button>
        </div>
      </div>
    </div>
    // </m.div>
  );
}

export default Membership;
