import React from 'react'
import {motion as m} from "framer-motion";
import {fadeIn} from '../components/transitions'
import s1 from '../images/S1.jpg'
import s2 from '../images/S2.jpg'
import s3 from '../images/S3.jpg'
import s4 from '../images/S4.jpg'
import s5 from '../images/S5.jpg'
import s6 from '../images/S6.jpg'
import s7 from '../images/S7.jpg'
import s8 from '../images/S8.jpg'
import s9 from '../images/S9.jpg'
import s10 from '../images/S10.jpg'
import s11 from '../images/S11.jpg'
import s12 from '../images/S12.jpg'
import s13 from '../images/S13.jpg'
import s14 from '../images/S14.jpg'
const Eventss = () => {
  return (
    <>
    <div className='space'>
   <div className='aboutsec'>
                <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='about-heading'>SPACE <span>DAY</span></m.h2>
                <m.p
                variants={fadeIn("up",0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}}  className='about-paragraph'>
               We are pleased to share that the SIST ACM SIGAI Student Chapter, in collaboration with the Centre for Remote Sensing and Informatics, conducted an event on the occasion of National Space Day on 22/08/2024 (Thursday) at Sathyabama Institute of Science and Technology, Chennai. Students from GHSS Perungudi and Evergreen School were invited to the university to participate in the event.

                </m.p>
                <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  className='about-heading'>EVENT <span>GALLERY</span></m.h2>
                
                <div className='sl2'>
    <div className='slider2'>
      <div className='slide-track'>
        <div className='slide'>
        <img src={s1} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s2} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s3} alt='logo'/>
        </div>
        
        <div className='slide'>
        <img src={s5} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s6} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s7} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s8} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s9} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s10} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s11} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s12} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s13} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s14} alt='logo'/>
        </div>
        


        <div className='slide'>
        <img src={s1} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s2} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s3} alt='logo'/>
        </div>
        
        <div className='slide'>
        <img src={s5} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s6} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s7} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s8} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s9} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s10} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s11} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s12} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s13} alt='logo'/>
        </div>
        <div className='slide'>
        <img src={s14} alt='logo'/>
        </div>

      </div>
    </div>
    
</div>
<div className='about-heading'>GUESTS OF <span>HONOUR</span></div>
<m.div variants={fadeIn("right",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  className='card-container1'>
   
      <div className='cards1'>
        <div className='imbx15'>
          <img src={s4} alt='img' />
        </div>
        <div className='contents'>
          <div className='contentbx'>
            <h3>Padma Shri Dr. Mylswamy Annadurai<br /><span>MOON MAN OF INDIA</span></h3>
          </div>
          
        </div>
      </div>
      </m.div>
      <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  className='about-heading'>🔍KEY <span>HIGHLIGHTS</span></m.h2>
      <m.p variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  className='about-paragraph'>The morning session began with an orientation, during which <strong>Dr. Vigneshwari</strong>, Head of the Department of Computer Science (specializations in AI, AIML, DS), along with, <strong>Dr. K Nagamani</strong>, delivered brief speeches outlining the day's events. This was followed by three competitions—painting, quiz, and ideathon—conducted simultaneously. In the painting competition, the participating students were provided with chart paper and given multiple space-related themes to illustrate in their paintings. The students enthusiastically participated, showcasing their artistic talents. The quiz competition began with an interactive riddle session in which they actively participated, followed by a space quiz where the students took part in an online competition, answering space-related questions. The students also prepared informative presentations for the ideathon competition and expressed their ideas in front of the audience and jury. Lunch was provided to all the school students by the university management.

                    The afternoon session featured a workshop led by our chief guest, the esteemed <strong>Padma Shri Dr. Mylswamy Annadurai</strong>, known as the <strong>"Moon Man of India"</strong>. He graciously addressed the event, interacting with all the students. He conducted a seminar on space science and exploration for future India, which captivated the students' interest.
                    
                    The event concluded with a prize distribution ceremony. All the winners of the painting, quiz, and ideathon contests were awarded certificates and Decathlon vouchers worth Rs. 3000, Rs. 2000, and Rs. 1000 for first, second, and third prizes, respectively. The prizes were handed over to the students by our honorable chief guest. 
                    
                    Overall, the school students thoroughly enjoyed participating in the events. Transport was provided to all the students. The event was a grand success, and all the dignitaries praised it. We are grateful to all the faculty coordinators, student coordinators, and volunteers who contributed to the success of the event.
                </m.p><br/>
              

            </div>








   
    </div>
    </>
  )
}
export default Eventss