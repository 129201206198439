import React from 'react';
import {motion as m} from "framer-motion";
import {fadeIn} from '../components/transitions'
import c1 from '../images/C1.jpg'
import c2 from '../images/C2.jpg'
import c3 from '../images/C3.jpg'
import c4 from '../images/C4.jpg'
import c5 from '../images/C5.jpg'
import c6 from '../images/C6.jpg'
import c7 from '../images/C7.jpg'
import c8 from '../images/C8.jpg'
import harsha from '../images/HARSHA.jpg'
import kishore from '../images/kishore.jpg'

const Azures = () => {
  return (
    <>
      <div className='events'>
        <div className='aboutsec'>
          <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  className='about-heading'>FUN <span>ACTIVITIES</span></m.h2>
          <m.p variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='about-paragraph'>
            We are thrilled to announce that the SIST ACM SIGAI Student Chapter hosted its first collaborative event on August 1, 2024, in the Remibai Auditorium at Sathyabama Institute of Science and Technology, Chennai. This event was organized in partnership with the School of Science and Humanities.  A special thank you to<strong> Dr. Rekha Chakravarthi</strong>, Dean of the Arts and Sciences department, for facilitating the collaboration.
          </m.p>
          <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='about-heading'>EVENT <span>GALLERY</span></m.h2>
          <m.div variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='sl'>
            <div className='slider'>
              <div className='slide-track'>
                <div className='slide'>
                  <img src={c1} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c2} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c3} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c4} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c5} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c8} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c6} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c7} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c8} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c1} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c2} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c3} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c4} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c5} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c6} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c7} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={c8} alt='logo' />
                </div>
                
              </div>
            </div>
          </m.div>
          <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='about-heading'>OUR <span>SPEAKERS</span></m.h2>
        <div className='cardgen'>
          <m.div variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='card-container1'>
            <div className='cards1'>
              <div className='imbx15'>
                <img src={harsha} alt='img' />
              </div>
              <div className='contents'>
                <div className='contentbx'>
                  <h3>Mr. Jonnalagadda sri harsha<br /><span>3rd year CSE-DS</span></h3>
                </div>
              </div>
            </div>
          </m.div>
          <m.div variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='card-container1'>
            <div className='cards1'>
              <div className='imbx15'>
                <img src={kishore} alt='img' />
              </div>
              <div className='contents'>
                <div className='contentbx'>
                  <h3>Mr. Kishore ramanan<br /><span>3rd year CSE-AIML</span></h3>
                </div>
              </div>
            </div>
          </m.div>
          </div>
          <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='about-heading'>🔍KEY <span>HIGHLIGHTS</span></m.h2>
          <m.p variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='about-paragraph'>
           The event focused on Generative AI and was attended by an enthusiastic crowd of second-year BSc students.<strong> Ms. Janllyn Avantikha</strong>, a core unit member, started the event by introducing the speakers and providing an overview about the session.
Two of our third-year students from the CSE Data Science specialization,<strong>Sri Harsha Jonnalagadda</strong>  and <strong>Kishore Ramanan</strong>, took the lead as speakers. In the first half of the event, they introduced the students to Generative AI and explained its applications in everyday life in detail. A live demonstration of generating content like text, images, and PPTs was conducted, and input prompts were taken from the audience, encouraging active participation from all students. In the second half of the event, a hands-on session based on the Ollama model was conducted.  This was followed by a digital art competition in which all the participants showcased their extraordinary talent. The event concluded with <strong>Ms. Vaishnavi Battina</strong>, a core team member of the club, delivering a vote of thanks.
The event has been lively and interactive.  It was well-received by all the students and dignitaries who attended. We are extremely grateful to the core unit, volunteers and all the people who contributed in making this event a success and we are look forward to hosting more such events in the future.

          </m.p>
        </div>
      </div>
    </>
  );
}

export default Azures;
