import React from 'react';
const Next = () => {
    return(
        <>
          <figure className='recruitment-figure'>
            <div className="dot white"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </figure>
<div className='stay'>
  <h1>Thank you for submitting the form!!<br/>
  Our chapter will reach you soon😊✌️


  </h1>

</div>
        </>
    )
}
export default Next;
