import React, { useState } from 'react';
import Loading from '../components/loading'; // Ensure this path is correct

const AzureCas = () => {
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formEle = e.target;
    const formData = new FormData(formEle);

    setIsLoading(true); // Show loading screen
    setIsSubmitted(true); // Disable submit button

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbyqbSJdrNGjRFTUtttLIdgz5yET4gsfA65qFDgNI0Jq4hzYIm2KOfMgxCB6aei4ng8/exec",
        {
          method: "POST",
          body: formData
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data);
      setSubmissionMessage('Form submitted successfully!');
      formEle.reset();

    } catch (error) {
      console.error('Error:', error);
      setSubmissionMessage('Form submission failed. Please try again.');
    } finally {
      // Always redirect after processing
      setTimeout(() => {
        window.location.href = 'https://aditya5teja.github.io/thanks/';
      }, 2000); // Delay to show the loading screen
    }
  };

  return (
    <div className='sect'>
      {isLoading ? (
        <Loading />
      ) : (
        <>
        <div className='sect'>
          <h1>COLLAB WITH ARTS AND SCIENCE</h1>

          <section className="containereve">
            <header>Registration Form</header>
            <form className="form" onSubmit={handleSubmit}>
              <div className="input-boxeve">
                <label htmlFor="fullname">Full Name*</label>
                <input className="Fullname" type="text" id="fullname" name="Fullname" placeholder="Enter full name" required />
              </div>
              <div className="input-boxeve">
                <label htmlFor="registerNumber">Register Number*</label>
                <input className="RegisterNumber" type="text" id="registerNumber" name="Regnumber" placeholder="Enter register number" required />
              </div>
              <div className="input-boxeve">
                <label htmlFor="email">Email Address*</label>
                <input className="Email" type="email" id="email" name="Email" placeholder="Enter email address" required />
              </div>
              <div className="columneve">
                <div className="input-boxeve">
                  <label htmlFor="Phonenumber">Phone Number*</label>
                  <input className="Phonenumber" type="tel" id="Phonenumber" name="Phonenumber" placeholder="Enter phone number" required />
                </div>
              </div>
              <div className="input-boxeve address">
                <div className="columneve">
                  <div className="select-box">
                    <select className="Spec" id="spec" name="Spec" required>
                      <option value="" hidden>Specialization*</option>
                      <option value="CSE-AIML">CSE-AIML</option>
                      <option value="CSE-AI">CSE-AI</option>
                      <option value="CSE-DS">CSE-DS</option>
                      <option value="CSE-CS">CSE-CS</option>
                      <option value="CSE-BlockChain">CSE-BlockChain</option>
                      <option value="CSE-IOT">CSE-IOT</option>
                    </select>
                  </div>
                  <div className="select-box">
                    <select className="Degree" id="degree" name="Degree" required>
                      <option value="" hidden>Degree*</option>
                      <option value="B.E">B.E</option>
                      <option value="B.Tech">B.Tech</option>
                    </select>
                  </div>
                </div>
                <div className="columneve">
                  <input className="Yop" type="text" id="yop" name="Yop" placeholder="Year of passing*" required />
                </div>
              </div>

              <button type="submit" disabled={isSubmitted}>Submit</button>
              <p>* fields are mandatory</p>
            </form>
            {submissionMessage && <p>{submissionMessage}</p>}
          </section>
          </div>
        </>
      )}
      
    </div>
  );
};

export default AzureCas;
