// src/App.js
import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Home from './components/Home';
import Membership from './components/Membership';
import Events from './components/Events';
import Blogs from './components/Blogs';
import Login from './components/Login';
import Eventss from './components/Eventss';
import Admin from './components/Admin';
import About from './components/About';
import Recruitment from './components/Recruitment';
import Sect from './components/Sect';
import Next from './components/Next';
import AzureCas from './components/AzureCas';
import Apex from './components/Apex';
import Azures from './components/Azure';
import ScrollToTop from './components/Scrolltoup';
import Loading from './components/loading';
import Title from './components/Title';
import Genai from './components/Genai'
import Funact from './components/Funact'
import Digi from './components/Digit'
import Media from './components/Media'
import Spaceday from './components/Spaceday'
import Synergy from './components/Synergy'
// import ImagePoll from './components/Poll'; 
import './App.css';

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      // Clean up any timers or effects
    };
  }, []);

  return (
    <div className="containerapp">
      <Nav />
      <Title />
      {/* <ImagePoll />  */}
      <AnimatePresence mode='wait'>
        {loading ? (
          <Loading />
        ) : (
          <motion.div
            className="main-contentapp"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/membership" element={<Membership />} />
              <Route path="/events" element={<Events />} />
              <Route path="/events/event1" element={<Eventss />} />
              <Route path="/events/event2" element={<Azures />} />
              <Route path="/events/event3" element={<Genai />} />
              <Route path="/events/event4" element={<Funact />} />
              <Route path="/events/event5" element={<Digi />} />
              <Route path="/events/SpaceDay" element={<Spaceday />} />
              <Route path="/events/Synergy" element={<Synergy />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/fest" element={<Login />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/recruitment" element={<Recruitment />} />
              <Route path="/recruitment/MEDIA-TEAM" element={<Media />} />
              <Route path="/fest/Event-registration" element={<AzureCas />} />
              <Route path="/fest/Apex" element={<Apex />} />
              <Route path="/recruitment/secretary/thanks" element={<Next />} />
            </Routes>
          </motion.div>
        )}
      </AnimatePresence>
      <Footer />
    </div>
  );
}

function Root() {
  return (
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  );
}

export default Root;
