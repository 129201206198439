import React from 'react';
import {motion as m} from "framer-motion";
import {fadeIn} from '../components/transitions'
import chief3 from '../images/BASHEER.jpg';
import log from '../images/w5.jpg';
import id2 from '../images/w7.jpg';
import grp1 from '../images/w8.jpg';
import grp2 from '../images/w9.jpg';
import vai from '../images/w1.jpg';
import sq from '../images/w6.jpg';
import mani from '../images/w3.jpg';
import adi from '../images/w4.jpg';
import sat from '../images/w2.jpg';

const Azures = () => {
  return (
    <>
      <div className='azure'>
        <div className='aboutsec'>
          <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  className='about-heading'>AZURE <span>CASCADE</span></m.h2>
          <m.p variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='about-paragraph'>
            🎉We are elated to share with you that <strong>SIST ACM SIGAI Student Chapter</strong> has successfully conducted it’s very first event which took place on 08/07/2024 at Dental Auditorium, Sathyabama University.
          
            The event began with two of our core team members<strong>Ms. Vaishnavi</strong>  and <strong>Ms. Vedha Varshini </strong> introducing our speaker <strong>Mr Harun Raseed Basheer</strong>, Microsoft MVP, Specialist, Hitachi Solutions India Pvt Ltd who had gracefully addressed the event.
            🌟
          </m.p>
          <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='about-heading'>EVENT <span>GALLERY</span></m.h2>
          <div  className='sl'>
            <div className='slider'>
              <div className='slide-track'>
                <div className='slide'>
                  <img src={vai} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={sat} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={mani} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={adi} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={log} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={sq} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={id2} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={grp1} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={grp2} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={vai} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={sat} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={mani} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={adi} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={log} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={sq} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={id2} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={grp1} alt='logo' />
                </div>
                <div className='slide'>
                  <img src={grp2} alt='logo' />
                </div>
              </div>
            </div>
          </div>
          <h2 className='about-heading'>GUESTS OF <span>HONOUR</span></h2>
          <m.div variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='card-container1'>
            <div className='cards1'>
              <div className='imbx15'>
                <img src={chief3} alt='img' />
              </div>
              <div className='contents'>
                <div className='contentbx'>
                  <h3>Mr. Harun Raseed Basheer<br /><span>Specialist, Hitachi Solutions India Pvt Ltd</span></h3>
                </div>
              </div>
            </div>
          </m.div>
          <m.h2 variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='about-heading'>🔍KEY <span>HIGHLIGHTS</span></m.h2>
          <m.p variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='about-paragraph'>
            The speaker took the lead making the session really interesting. By the end of the session, all the students in the room were familiar with the key concepts of cloud computing. The students were also informed in detail about the Microsoft Student Ambassador program and its benefits. The session has been interactive and extremely informative.
            
            After this session, the speaker was presented with a memento by our respected HOD, <strong>Dr. S Vigneshwari</strong> and <strong>Dr. R Sathyabama Krishna, Dr. Anubharathi.</strong>
           
            The event was then followed by an empowering speech about mental health given by our very own treasurer of SIST ACM SIGAI Student Chapter, <strong>Ms. Deekshitha</strong>. The audience was ecstatic after listening to her motivating words and her own experiences. The students actively participated in the discussion by sharing their personal views too.
         
            We concluded the event by launching our SIST ACM SIGAI Student Chapter’s official website. Our core team member <strong>Ms. Janllyn Avantika</strong> presented and explained all the features of the website to the audience.
         
            Overall it has been an overwhelming experience and we received a positive response from the students and the dignitaries who attended the event. We are excited and are looking forward to conducting more events this way and hope we get a similar, encouraging response.
          </m.p>
        </div>
      </div>
    </>
  );
}

export default Azures;
