import React, { useState } from 'react';
import Loading from './loading'; // Assuming the loading component is in the same directory

const Sect = () => {
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [wordCounts, setWordCounts] = useState({
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    extraSkills: 0,
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Track the submission status

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the button and show the loading screen

    const formEle = e.target;
    const formData = new FormData(formEle);

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbwqe2h3V6U5nj1lsZhfdO7RWBIlpSCtkl7V258FdBQ2P0cvNXwujdWLL6ch1bGHF-xn/exec",
        {
          method: "POST",
          body: formData
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setSubmissionMessage('Form submitted successfully!');
      formEle.reset();
      setWordCounts({
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
        extraSkills: 0,
      });

      window.location.href = '/Recruitment/Secretary/Thanks';
    } catch (error) {
      console.error('Error:', error);
      setSubmissionMessage('Form submitted successfully!');
      window.location.href = '/Recruitment/Secretary/Thanks';
    } finally {
      setIsSubmitting(false); // Hide loading screen after submission
    }
  };

  const handleWordCountChange = (e, fieldName) => {
    const value = e.target.value;
    const words = value.trim().split(/\s+/).filter(word => word.length > 0);

    if (fieldName !== 'q4') {
      if (words.length <= 50) {
        setWordCounts({ ...wordCounts, [fieldName]: words.length });
      } else {
        e.target.value = words.slice(0, 50).join(' ');
        setWordCounts({ ...wordCounts, [fieldName]: 50 });
      }
    } else {
      // No word limit for q4
      setWordCounts({ ...wordCounts, [fieldName]: words.length });
    }
  };

  return (
    <div className='sect'>
      <h1>MEDIA UNIT RECRUITMENT</h1>
      <section className="containereve">
        <header>Registration Form</header>
        {isSubmitting ? ( // Show loading screen if form is submitting
          <Loading />
        ) : (
          <form className="form" onSubmit={handleSubmit}>
            <div className="input-boxeve">
              <label htmlFor="fullname">Full Name*</label>
              <input className="Fullname" type="text" id="fullname" name="Fullname" placeholder="Enter full name" required />
            </div>
            <div className="input-boxeve">
              <label htmlFor="registerNumber">Register Number*</label>
              <input className="RegisterNumber" type="text" id="registerNumber" name="Regnumber" placeholder="Enter register number" required />
            </div>
            <div className="input-boxeve">
              <label htmlFor="email">Email Address*</label>
              <input className="Email" type="email" id="email" name="Email" placeholder="Enter email address" required />
            </div>
            <div className="columneve">
              <div className="input-boxeve">
                <label htmlFor="Phonenumber">Phone Number*</label>
                <input className="Phonenumber" type="tel" id="Phonenumber" name="Phonenumber" placeholder="Enter phone number" required />
              </div>
              <div className="input-boxeve">
                <label htmlFor="Birthdate">Birth Date*</label>
                <input className="Birthdate" type="date" id="Birthdate" name="Birthdate" required />
              </div>
            </div>
            <div className="input-boxeve address">
              <div className="columneve">
                <div className="select-box">
                  <select className="Spec" id="spec" name="Spec" required>
                    <option value="" hidden>Specialization*</option>
                    <option value="CSE-AIML">CSE-AIML</option>
                    <option value="CSE-AI">CSE-AI</option>
                    <option value="CSE-DS">CSE-DS</option>
                    <option value="CSE-CS">CSE-CS</option>
                    <option value="CSE-BlockChain">CSE-BlockChain</option>
                    <option value="CSE-IOT">CSE-IOT</option>
                    <option value="CSE-AI-ROBOTICS">CSE-AI-ROBOTICS</option>
                  </select>
                </div>
                <div className="select-box">
                  <select className="Degree" id="degree" name="Degree" required>
                    <option value="" hidden>Degree*</option>
                    <option value="B.E">B.E</option>
                    <option value="B.Tech">B.Tech</option>
                  </select>
                </div>
              </div>
              <div className="columneve">
                <input className="Yop" type="text" id="yop" name="Yop" placeholder="Year of passing*" required />
              </div>
              {/* New Dropdown added here */}
              <div className="columneve">
                <div className="select-box">
                  <select className="Experience" id="experience" name="Experience" required>
                    <option value="" hidden>Select Job Role*</option>
                    <option value="Photography">Photography</option>
                    <option value="Videography">Videography</option>
                    <option value="Editors">Editors</option>
                    <option value="Content Researcher">Content Researcher</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-boxeve">
              <label htmlFor="q1">1. What strategies do you use to ensure the content you produce is original and stands out in a crowded market?*</label><br />
              <textarea className="Q1" id="q1" name="Q1" placeholder="Enter your answer" required onChange={(e) => handleWordCountChange(e, 'q1')}></textarea>
              <p>{wordCounts.q1}/50</p>
            </div>
            <div className="input-boxeve">
              <label htmlFor="q2">2. How do you collaborate with other creatives (e.g., editors, writers, designers) to ensure the final product is cohesive and meets the project’s objectives?*</label><br />
              <textarea className="Q2" id="q2" name="Q2" placeholder="Enter your answer" required onChange={(e) => handleWordCountChange(e, 'q2')}></textarea>
              <p>{wordCounts.q2}/50</p>
            </div>
            <div className="input-boxeve">
              <label htmlFor="q3">3. How do you ensure that the content you produce is aligned with the brand's voice and target audience?*</label><br />
              <textarea className="Q3" id="q3" name="Q3" placeholder="Enter your answer" required onChange={(e) => handleWordCountChange(e, 'q3')}></textarea>
              <p>{wordCounts.q3}/50</p>
            </div>
            <div className="input-boxeve">
              <label htmlFor="q4">4. Could you please share your previous works or portfolios? You can provide links that best showcase your skills and experience.For example:-You can add a google drive or insta page where all your works are alligned in a single page. (If it's a google drive link make sure anyone can access with this link is enabled)*</label><br />
              <textarea className="Q4" id="q4" name="Q4" placeholder="Enter your answer" required onChange={(e) => handleWordCountChange(e, 'q4')}></textarea>
            </div>
            <div className="input-boxeve">
              <label htmlFor="extraskills">Extra personal skills you possess? (optional)</label><br />
              <textarea className="Extraskills" id="extraskills" name="Extraskills" placeholder="Enter your answer" onChange={(e) => handleWordCountChange(e, 'extraSkills')}></textarea>
              <p>{wordCounts.extraSkills}/50</p>
            </div>

            <button className="Submitbtn" type="submit" disabled={isSubmitting}>Submit</button>
          </form>
        )}
      </section>
      {submissionMessage && <p>{submissionMessage}</p>}
    </div>
  );
};

export default Sect;
